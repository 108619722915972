import React, {Component} from 'react';
import { graphql, StaticQuery } from 'gatsby';
import loader from "../../images/enquiry-loader.gif";

class Content extends Component {

    state = {
        name: '',
        email: '', 
        query: ''
    };

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
          [name]: value,
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        document.getElementById('enquiry-button').className = "add-spinner";

        if(this.state.name && this.state.email && this.state.query) {
            var xhr = new XMLHttpRequest()

            xhr.addEventListener('load', (data) => {
                let res             = JSON.parse(data.target.response);
                
                if(res.status == "success") {

                    document.getElementById('success').style.opacity    = 1;
                    document.getElementById('success').style.zIndex     = 100000;
                    document.getElementById('enquiry-button').classList.remove("add-spinner");
                    setTimeout(() => {
                        document.getElementById('success').style.opacity    = 0;
                        document.getElementById('success').style.zIndex     = 0;
                    }, 5000)
            
                } else {
                    document.getElementById('error').style.opacity    = 1;
                    document.getElementById('error').style.zIndex     = 100000;
                    document.getElementById('enquiry-button').classList.remove("add-spinner");
                    setTimeout(() => {
                        document.getElementById('error').style.opacity    = 0;
                        document.getElementById('error').style.zIndex     = 0;
                    }, 5000)
                }
                
            })

                // open the request with the verb and the url
                xhr.open('POST', 'https://api.studio137.io/v1/websites/contactForms/1')
                // xhr.open('POST', 'http://api.studio137.test/v1/websites/contactForms/1')
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                xhr.send(JSON.stringify({
                    name: this.state.name,
                    email: this.state.email,
                    query: this.state.query
                }))

            
        } else {
            document.getElementById('error').style.opacity    = 1;
            document.getElementById('error').style.zIndex     = 100000;
            document.getElementById('enquiry-button').classList.remove("add-spinner");
            setTimeout(() => {
                document.getElementById('error').style.opacity    = 0;
                document.getElementById('error').style.zIndex     = 0;
            }, 5000)
        }
    }

    render() {
    return <StaticQuery query={graphql`
    {
        contact: wordpressPage(slug: { eq: "contact" }){
            acf {
                heading
                subheading
                content
                name_placeholder
                email_address_placeholder
                query_placeholder
                submit_button
                contact_detail_heading
                contact_detail_content
                address_heading
                address_content
                error_1
                error_2
                success_1
                success_2
            }
        }
        home: wordpressPage(title: { eq: "Home" }){
            acf {
                top_background_image {
                    source_url
                }
            }
        }
    }
    `} render={props => (
        <div className="frame">
            <div className="contact-content">
                <div className="top-background-image">
                    <img src={props.home.acf.top_background_image.source_url} />
                </div>
                <div className="title" data-aos="fade-up">{ props.contact.acf.heading }</div>
                <div className="subtitle" data-aos="fade-up">{ props.contact.acf.subheading }</div>
                <div className="content" data-aos="fade-up" dangerouslySetInnerHTML = {{ __html: props.contact.acf.content }}></div>
                <form className="contact-form" onSubmit={this.handleSubmit}>
                    <input type="text" data-aos="fade-up" name="name" value={this.state.name} onChange={this.handleInputChange} placeholder={ props.contact.acf.name_placeholder } />
                    <input type="email" data-aos="fade-up" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder={ props.contact.acf.email_address_placeholder } />
                    <textarea type="text" data-aos="fade-up" name="query" value={this.state.query} onChange={this.handleInputChange} placeholder={ props.contact.acf.query_placeholder } />
                    <button type="submit" data-aos="fade-up" id="enquiry-button">
                        <span>{ props.contact.acf.submit_button }</span>
                        <img src={loader} />
                    </button>
                </form>
                <div className="popup-success" id="success"><strong>{ props.contact.acf.success_1 }</strong><br/>We'll get back to you in 48 hours</div>
                <div className="popup-error" id="error"><strong>{ props.contact.acf.error_1 }</strong><br/>{ props.contact.acf.error_2 }</div> 
                <div className="contact-address">
                    <div className="line line1" data-aos="fade-up"></div>
                    <div className="contact-heading" data-aos="fade-up">
                        {props.contact.acf.contact_detail_heading }
                    </div>
                    <div className="contact-address-content" data-aos="fade-up" dangerouslySetInnerHTML = {{ __html: props.contact.acf.contact_detail_content}}></div>
                    <div className="line line1" data-aos="fade-up"></div>
                    <div className="contact-heading" data-aos="fade-up">
                        {props.contact.acf.address_heading }
                    </div>
                    <div className="contact-address-content" data-aos="fade-up" dangerouslySetInnerHTML = {{ __html: props.contact.acf.address_content}}></div>
                </div>
            </div>
        </div>
    ) } />
    }
};

export default Content;